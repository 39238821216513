<template>
  <div>
    <v-dialog
      v-model="dialog"
      :max-width="messageIcon == 'schedule' ? 450 : 400"
      max-height="350"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card class="pt-12 overflow-hidden">
        <v-row
          justify="center"
          class="mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : ''"
        >
          <img
            v-if="messageIcon == 'approval'"
            src="@/assets/images/approval.svg"
            alt="Approval Logo"
          />
          <img
            v-if="messageIcon == 'schedule'"
            src="@/assets/images/schedule.svg"
            alt="Approval Logo"
          />
        </v-row>
        <div
          id="messageHeaderLabel"
          class="d-flex justify-center transition-swing font-weight-bold text-h5 mb-4"
        >
          {{ $t(headerMessage) }}
        </div>
        <span
          id="messageHeaderLabel"
          class="d-flex text-center justify-center transition-swing font-weight-medium mb-4"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'text-subtitle-2 mx-12'
              : 'text-subtitle-1 mx-16'
          "
        >
          {{ text }}
        </span>
        <v-row justify="space-around" class="mt-8 mb-8 mx-auto">
          <v-col cols="4" sm="4" lg="4" md="4" class="mx-auto">
            <v-btn
              color="primary"
              block
              class="text-none body-2 rounded-lg"
              width="128"
              @click="closeDialog"
              >{{ $t("done") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    messageIcon: {
      type: String,
      default: () => "",
    },
    headerMessage: {
      type: String,
      default: () => "",
    },
    text: {
      type: String,
      default: () => "",
    },
    dialog: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("onCloseDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
