var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": _vm.messageIcon == "schedule" ? 450 : 400,
            "max-height": "350",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pt-12 overflow-hidden" },
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-4",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mt-16" : "",
                  attrs: { justify: "center" },
                },
                [
                  _vm.messageIcon == "approval"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/images/approval.svg"),
                          alt: "Approval Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.messageIcon == "schedule"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/images/schedule.svg"),
                          alt: "Approval Logo",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-center transition-swing font-weight-bold text-h5 mb-4",
                  attrs: { id: "messageHeaderLabel" },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.headerMessage)) + " ")]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "d-flex text-center justify-center transition-swing font-weight-medium mb-4",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "text-subtitle-2 mx-12"
                    : "text-subtitle-1 mx-16",
                  attrs: { id: "messageHeaderLabel" },
                },
                [_vm._v(" " + _vm._s(_vm.text) + " ")]
              ),
              _c(
                "v-row",
                {
                  staticClass: "mt-8 mb-8 mx-auto",
                  attrs: { justify: "space-around" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mx-auto",
                      attrs: { cols: "4", sm: "4", lg: "4", md: "4" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none body-2 rounded-lg",
                          attrs: { color: "primary", block: "", width: "128" },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(_vm._s(_vm.$t("done")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }